/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
// @mui material components
import Card from "@mui/material/Card";

// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
// import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import Footer from "examples/Footer";
import Grid from "@mui/material/Grid";
import { Oval } from 'react-loader-spinner';
import Select from 'react-select';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as service from '../../../../services/service';


function Basic() {
  sessionStorage.clear();
  const [state, setState] = useState({ email: '', password: '' });
  const [loginFor, setLoginFor] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const forData = [{ label: "Non Financial Logo Services", value: "Non Financial Logo Services" },
  { label: "Sponsorship Services", value: "Sponsorship Services" }];
  const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
  const navigate = useNavigate();
  const validate = () => {
    let isValid = true;
    const errs = {};
    if (!state.email) {
      isValid = false;
      errs[".email"] = "Please enter email";
    }
    // if (state.email !== "undefined") {
    //   const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    //   if (!pattern.test(state.email)) {
    //     isValid = false;
    //     errs[".email"] = "Please enter valid email address";
    //   }
    // }
    if (!state.password) {
      isValid = false;
      errs[".password"] = "Please enter password";
    }
    if (!loginFor) {
      isValid = false;
      errs[".loginFor"] = "Please select";
    }
    setErrors(errs);
    return isValid;
  }

  const submitHandler = (event) => {
    event.preventDefault();
    const errs = {};
    let url = '';
    setLoading(true);
    if (validate()) {
      if (loginFor.value === "Non Financial Logo Services") {
        url = service.NFLS_LOGIN_URL;
      }
      else if (loginFor.value === "Sponsorship Services") {
        url = service.SPONSORSHIP_LOGIN_URL;
      }

      fetch(
        url,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            EmailId: state.email,
            Password: state.password,
          })
        })
        .then(data => data.json())
        .then(userData => {
          if (userData.message.messageType === 'Success') {
            const returnObj = userData;
            sessionStorage.setItem('userData', JSON.stringify(returnObj));

            if (loginFor.value === "Non Financial Logo Services") {
              navigate(`/viewnflsregistration/${returnObj.nflsRegistrationId}`);
            }
            else if (loginFor.value === "Sponsorship Services") {
              navigate(`/viewsponsorship/${returnObj.sponsorshipId}`);
            }
          }
          else {
            errs[".invalid"] = userData.message.message;
            setErrors(errs);
          }
        });
    }
    setLoading(false);
  };

  const changeHandler = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };
  const loginForChangeHandler = (ev) => {
    setLoginFor(ev);
  }

  const nflsHandler = () => {
    navigate('/nflsregistration');
  }
  const sponsorshipHandler = () => {
    navigate('/sponsorship');
  }

  return (
    <div style={{ width: '100%', alignItems: 'center' }}>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Sign in / Registration
                </MDTypography>
              </MDBox>
              <Card style={{ alignItems: 'center' }}>
                <TableContainer component={Paper} style={{ width: '99%', height: '600px', alignSelf: 'center' }}>
                  <Table>
                    <TableRow>
                      <TableCell>
                        <MDBox mb={2} style={{ fontSize: '16px', fontWeight: 'bold' }}>
                          New Registration
                        </MDBox>
                        <MDBox>
                          <MDButton variant="gradient" color="info" fullWidth onClick={nflsHandler}>
                            Non Financial Logo Services
                          </MDButton>
                        </MDBox>
                        <br />
                        <MDBox>
                          <MDButton variant="gradient" color="info" fullWidth onClick={sponsorshipHandler}>
                            Sponsorship Services
                          </MDButton>
                        </MDBox>
                      </TableCell>
                      <TableCell width="1%">
                        <div style={{ 'borderLeft': '2px solid grey', 'height': '500px' }} />
                      </TableCell>
                      <TableCell width="45%">
                        <MDBox mb={2} style={{ fontSize: '16px', fontWeight: 'bold' }}>
                          Already Registered?
                          <br />
                          Non Financial Logo Services/ Sponsorship Services रजिस्ट्रेशन  केल्यावर आपल्या रजिस्टर्ड ई-मेल आयडी वर पाठवले गेलेले लॉगिन आयडी आणि सांकेतिक शब्द वापरून आपण आपल्या अर्जाची सद्यस्थिती बघू शकता.
                        </MDBox>
                        <MDBox mb={2}>
                          <MDInput type="email" label="Email" fullWidth value={state.email || ''}
                            name="email"
                            onChange={changeHandler}
                            id="email"
                            required />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".email"]}</div>
                        </MDBox>
                        <MDBox mb={2}>
                          <MDInput type="password" label="Password" fullWidth value={state.password || ''}
                            name="password"
                            onChange={changeHandler}
                            id="password"
                            required />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".password"]}</div>
                        </MDBox>
                        <MDBox mb={2}>
                          <div style={{ fontSize: '14px' }}>
                            <Select
                              options={forData}
                              name="loginFor"
                              value={loginFor}
                              onChange={loginForChangeHandler}
                              placeholder="Select Type"
                              isSearchable
                              styles={selectStyles}
                            />
                          </div>
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".loginFor"]}</div>
                        </MDBox>
                        <MDBox mt={4} mb={1}>
                          {loading ? <Oval
                            heigth="20"
                            width="20"
                            color='grey'
                            ariaLabel='loading'
                          /> :
                            <MDButton variant="gradient" color="info" fullWidth onClick={submitHandler}>
                              sign in
                            </MDButton>
                          }
                        </MDBox>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan="5" align="center">
                        {errors ? <div style={{ fontSize: '14px', color: 'red', fontWeight: 'bold' }}>{errors[".invalid"]}</div> : null}
                      </TableCell>
                    </TableRow>
                  </Table>
                </TableContainer>
              </Card>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </div >
  );
}

export default Basic;
