// const baseURL = 'https://localhost:44389/api/';
 const baseURL = 'https://nflsapi.dotregistration.co.in/api/';

export const NFLS_LOGIN_URL = `${baseURL}Account/nflsapplicantlogin`;
export const SPONSORSHIP_LOGIN_URL = `${baseURL}Account/sponsorshipapplicantlogin`;

export const STATE_URL = `${baseURL}Masters/getstates`;
export const GET_REGION_BY_STATE_ID = `${baseURL}Masters/getregionssbystateid`;
export const GET_DISTRICT_BY_REGION_ID = `${baseURL}Masters/getdistrictsbyregionid`;

export const GET_NFLS_REGISTRATION_BY_ID = `${baseURL}Applicant/getnflsregistrationbyid`;
export const GET_NFLS_DOCUMENT_BY_ID = `${baseURL}Applicant/getnflsdocumentsbyid`;
export const ADD_NFLS_REGISTRATION = `${baseURL}Applicant/addnflsregistration`;
export const UPDATE_NFLS_DOCUMENT_BY_ID = `${baseURL}Applicant/updatenflsdocumentbyid`;

export const GET_SPONSORSHIP_BY_ID = `${baseURL}Applicant/getsponsorshipbyid`;
export const GET_SPONSORSHIP_DOCUMENT_BY_ID = `${baseURL}Applicant/getsponsorshipdocumentsbyid`;
export const ADD_SPONSORSHIP_REGISTRATION = `${baseURL}Applicant/addsponsorship`;
export const UPDATE_SPONSORSHIP_DOCUMENT_BY_ID = `${baseURL}Applicant/updatesponsorshipdocumentbyid`;

 export const GET_NFLS_CERTIFICATE_BY_ID = `${baseURL}Admin/getnflscertificatebyid`;

export const DOWNLOAD_FILE = `${baseURL}Admin/downloadfile`;

// *************************************************************************************************

export default NFLS_LOGIN_URL;